.footer {
  margin-top: 150px;
  background-image: url("../assets/bg-footer-squiggle.svg");
  height: 228px;
  text-align: center;
  h2 {
    font-size: 32px;
    color: #ffff;
    font-size: 800;
    margin-bottom: 42px;
  }
  form {
    width: 476px;
    display: grid;
    grid-template-columns: 75% 25%;
    grid-gap: 10px;
    margin: 0 auto;
  }
  input {
    height: 48px;
    background: #093f68;
    border-radius: 24px;
    border: none;
    padding: 14px;
  }
  input::placeholder {
    color: #fff;
    font-weight: 600;
    opacity: 40%;
    font-family: "Manrope";
  }
  button {
    height: 48px;
    background: #3ee9e5;
    border-radius: 24px;
    border: none;
    margin-left: 16px;
    font-weight: 600;
    color: #093f68;
    font-family: "Manrope";
  }
  @media screen and (max-width: 550px) {
    form {
      width: 350px;
      display: grid;
      grid-template-columns: 100% ;
      grid-gap: 20px;
      margin: 0 auto;
    }
    h2 {
      font-size: 24px;
      padding: 0 40px;
    }
    button {
      height: 48px;
      margin-left: 0;
    }
  }
  @media screen and (max-width: 400px) {
    form {
      width: 300px;
      display: grid;
      grid-template-columns: 100% ;
      grid-gap: 20px;
      margin: 0 auto;
    }
    h2 {
      font-size: 24px;
      padding: 0 40px;
    }
    button {
      height: 48px;
      margin-left: 0;
    }
  }
}
