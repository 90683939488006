.first {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/Hero-images.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 510px;
    margin-top: 100px;
    h1 {
      color: var(--header-color);
      font-weight: 800;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      margin: 0 500px 25px 500px;
    }
    p {
      line-height: 25px;
      text-align: center;
      margin: 0 450px;
    }
    img {
      display: block;
      margin: 0 auto;
      margin-top: 55px;
    }
    @media screen and (max-width: 1300px) {
      background-position: center;
      height: 400px;
      h1 {
        margin: 0 220px 25px 230px;
      }
      p {
        margin: 0 200px 25px 200px;
      }
    }
    @media screen and (max-width: 850px) {
      h1 {
        margin: 0 170px 25px 170px;
        font-size: 32px;
      }
      p {
        margin: 0 150px 25px 150px;
      }
    }
    @media screen and (max-width: 550px) {
      background-image: url("../assets/illustration-hero-mobile.png");
      background-position: top;
      height: 408px;
      background-size: contain;
      margin-top: 65px;
      margin-bottom: 300px;
      h1 {
        margin: 450px 55px 25px 54px;
        font-size: 32px;
        line-height: 1.3;
      }
      p {
        margin: 0 24px 0px 24px;
      }
      img {
        margin-top: 32px;
      }
    }
  }