@forward "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Outfit:wght@400;700&family=Roboto:wght@400;500;700;900&family=Space+Grotesk:wght@500&display=swap");
body {
  padding: 80px 0;
  box-sizing: border-box;
  background-color: var(--background-color);
  font-family: var(--font);
  letter-spacing: 1px;
  @media screen and (max-width: 450px) {
    padding: 40px 0;
  }
  p {
    color: var(--body-color);
    font-size: 15px;
  }
  .App {
    nav {
      max-width: 1100px;
      margin: 0 auto;
      @media screen and (max-width: 1100px) {
        img {
          margin-left: 70px;
        }
      }
      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        text-align: center;
        img {
          margin: 0;
        }
      }
    }
    // .third {
    //   margin-top: 200px;
    //   .pricing {
    //     margin: 0 auto 73px auto;
    //     text-align: center;
    //     width: 550px;
    //     h2 {
    //       color: var(--header-color);
    //       margin-bottom: 24px;
    //     }
    //   }
    //   .plans {
    //     display: flex;
    //     margin: 0 auto;
    //     gap: 30px;
    //     width: 900px;
    //     position: relative;

    //     .free {
    //       background-color: var(--second-bg);
    //       width: 445px;
    //       padding: 38px;
    //       border-radius: 8px;
    //       margin-top: 20px;
    //       margin-bottom: 10px;
    //       .img-icon {
    //         position: absolute;
    //         top: -10px;
    //       }
    //       .price {
    //         font-size: 40px;
    //         color: #ffff;
    //         opacity: 1;
    //         font-weight: 800;
    //       }
    //       h4 {
    //         color: #ffff;
    //         font-weight: 800;
    //         font-size: 18px;
    //       }
    //       p {
    //         margin: 19px 0 24px 0;
    //         color: #ffffff;
    //         opacity: 0.6;
    //       }
    //       ul {
    //         list-style-type: none;
    //         margin-left: 15px;
    //         padding: 0;
    //       }

    //       li {
    //         margin-bottom: 10px;
    //         size: 15px;
    //         color: white;
    //         span {
    //           margin-left: 15px;
    //         }
    //       }
    //     }
    //     .monthly {
    //       background-color: var(--theme-color);
    //       width: 445px;
    //       padding: 38px;
    //       border-radius: 8px;
    //       .img-icon {
    //         position: absolute;
    //         top: -40px;
    //       }
    //       .price {
    //         font-size: 40px;
    //         color: #000000;
    //         opacity: 1;
    //         font-weight: 800;
    //       }
    //       p {
    //         color: #080c20;
    //         opacity: 0.6;
    //         margin: 19px 0 24px 0;
    //       }
    //       ul {
    //         list-style-type: none;
    //         margin-left: 15px;
    //         padding: 0;
    //       }

    //       li {
    //         margin-bottom: 10px;
    //         span {
    //           margin-left: 15px;
    //         }
    //       }
    //     }
    //   }
    //   @media screen and (max-width: 900px) {
    //     .plans {
    //       width: 100%;
    //       flex-direction: column;
    //       .free {
    //         margin: 0px 150px;
    //         width: 600px;
    //         img {
    //           top: -22px !important;
    //         }
    //       }
    //       .monthly {
    //         margin: 65px 150px 0 150px;
    //         max-width: 600px;
    //         width: 100%;
    //         img {
    //           top: 440px !important;
    //         }
    //       }
    //     }
    //   }
    //   @media screen and (max-width: 850px) {
    //     .plans {
    //       width: 100%;
    //       flex-direction: column;
    //       .free {
    //         margin: 0px 150px;
    //         width: 550px;
    //         img {
    //           top: -22px !important;
    //         }
    //       }
    //       .monthly {
    //         margin: 65px 150px 0 150px;
    //         max-width: 550px;
    //         width: 100%;
    //         img {
    //           top: 440px !important;
    //         }
    //       }
    //     }
    //   }
    //   @media screen and (max-width: 768px) {
    //     .plans {
    //       width: 100%;
    //       flex-direction: column;
    //       .free {
    //         margin: 0px 150px;
    //         width: 455px;
    //         img {
    //           top: -22px !important;
    //         }
    //       }
    //       .monthly {
    //         margin: 65px 150px 0 150px;
    //         width: 455px;
    //         img {
    //           top: 440px !important;
    //         }
    //       }
    //     }
    //   }
    //   @media screen and (max-width: 668px) {
    //     .pricing {
    //       width: calc(80% - 30px);
    //     }
    //     .plans {
    //       width: 100%;
    //       flex-direction: column;
    //       .free {
    //         margin: 0px 100px;
    //         width: calc(80% - 30px);
    //         img {
    //           top: -22px !important;
    //         }
    //       }
    //       .monthly {
    //         margin: 65px 100px 0 100px;
    //         width: calc(80% - 30px);
    //         img {
    //           top: 440px !important;
    //         }
    //       }
    //     }
    //   }
    //   @media screen and (max-width: 500px) {
    //     .pricing {
    //       width: calc(80% - 30px);
    //     }
    //     .plans {
    //       width: 100%;
    //       flex-direction: column;
    //       gap: 70px;
    //       .free {
    //         margin: 0px 60px;
    //         width: calc(80% - 20px);
    //         img {
    //           top: -22px !important;
    //         }
    //       }
    //       .monthly {
    //         margin: 0px 60px;
    //         width: calc(80% - 20px);
    //         img {
    //           top: 440px !important;
    //         }
    //       }
    //     }
    //   }
    //   @media screen and (max-width: 400px) {
    //     margin-top: 144px;

    //     .pricing {
    //       width: 100%;
    //       padding: 0 10px;
    //       h2{
    //         font-size: 24px;
    //       }
    //     }
    //     .plans {
    //       width: 100%;
    //       flex-direction: column;
    //       padding: 0 24px;
    //       gap: 65px;
    //       .free {
    //         margin: 0px;
    //         padding: 25px;
    //         width: 100%;
    //         h4 {
    //           padding-top: 26px;
    //         }
    //         img {
    //           top: -27px !important;
    //         }
    //       }
    //       .monthly {
    //         margin: 0;
    //         width: 100%;
    //         padding: 25px;
    //         h4 {
    //           padding-top: 26px;
    //         }
    //         img {
    //           top: 462px !important;
    //         }
    //       }
    //     }
    //   }
    // }
    // .footer {
    //   margin-top: 150px;
    //   background-image: url("./assets/bg-footer-squiggle.svg");
    //   height: 228px;
    //   text-align: center;
    //   h2 {
    //     font-size: 32px;
    //     color: #ffff;
    //     font-size: 800;
    //     margin-bottom: 42px;
    //   }
    //   input {
    //     width: 320px;
    //     height: 48px;
    //     background: #093f68;
    //     border-radius: 24px;
    //     border: none;
    //     padding: 14px;
    //   }
    //   input::placeholder {
    //     color: #fff;
    //     font-weight: 600;
    //     opacity: 40%;
    //     font-family: "Manrope";
    //   }
    //   button {
    //     height: 48px;
    //     width: 140px;
    //     background: #3ee9e5;
    //     border-radius: 24px;
    //     border: none;
    //     margin-left: 16px;
    //     font-weight: 600;
    //     color: #093f68;
    //     font-family: "Manrope";
    //   }
    //   @media screen and (max-width: 400px){
    //     h2{
    //       font-size: 24px;
    //       padding: 0 40px;
    //     }
    //     button{
    //       width: 320px;
    //     height: 48px;
    //     margin-left: 0;
    //     margin-top: 24px;
    //     }
    //   }
    // }
  }
}
